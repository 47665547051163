import { render, staticRenderFns } from "./classJgCourse.vue?vue&type=template&id=70b8d51c&scoped=true"
import script from "./classJgCourse.vue?vue&type=script&lang=js"
export * from "./classJgCourse.vue?vue&type=script&lang=js"
import style0 from "./classJgCourse.vue?vue&type=style&index=0&id=70b8d51c&prod&lang=less"
import style1 from "./classJgCourse.vue?vue&type=style&index=1&id=70b8d51c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70b8d51c",
  null
  
)

export default component.exports