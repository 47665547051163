<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">班级审核</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">详情</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="df" style="padding: 15px 0; justify-content: space-between"
              v-show="ruleForm.auditState == '40'">
              <div style="
                  max-width: 960px;
                  flex-grow: 0.6;
                  padding: 20px;
                  background-color: rgb(253, 237, 237);
                  border-radius: 15px;
                " class="auditReason">
                <h3 style="color: rgb(189, 63, 63); margin-bottom: 10px">
                  <i style="font-weight: 600; margin-right: 6px" class="el-icon-warning-outline"></i>您的课程审核未通过，原因如下
                </h3>
                <p style="
                    font-size: 12px;
                    color: #999;
                    padding: 0 20px;
                    letter-spacing: 1px;
                    width: 100%;
                    word-break: break-all;
                  ">
                  {{ ruleForm.auditReason }}
                </p>
                <!-- <h3 style="padding-bottom: 10px">审核事由</h3>
                <el-input
                  type="textarea"
                  readonly
                  v-model="ruleForm.auditReason"
                ></el-input> -->
              </div>
            </div>
            <div style="display: flex; justify-content: flex-end">
              <el-button size="mini" class="bgc-bv" @click="$router.back()">返回</el-button>
            </div>
            <h3 class="flexcb doedit" style="padding-bottom: 20px">
              <span>课程基本信息</span>
            </h3>
            <div class="form-detail">
              <el-form :inline="true" ref="ruleForm" label-width="10rem">
                <el-form-item label="课程名称：" class="form-inline">
                  <p>{{ ruleForm.courseName }}</p>
                </el-form-item>
                <el-form-item v-show="ruleForm.trainTypeNamePath" label="培训类型：" class="form-inline">
                  <p>{{ ruleForm.trainTypeNamePath || "" }}</p>
                </el-form-item>
                <el-form-item v-show="ruleForm.industryNamePath" label="行业类型：" class="form-inline">
                  <p>{{ ruleForm.industryNamePath || "" }}</p>
                </el-form-item>
                <el-form-item v-show="ruleForm.postName" label="岗位类型：" class="form-inline">
                  <p>{{ ruleForm.postName || "" }}</p>
                </el-form-item>
                <el-form-item v-show="ruleForm.occupationNamePath" label="职业/工种：" class="form-inline">
                  <p>{{ ruleForm.occupationNamePath || "" }}</p>
                </el-form-item>
                <el-form-item v-show="ruleForm.trainLevelName" label="培训等级：" class="form-inline">
                  <p>{{ ruleForm.trainLevelName || "" }}</p>
                </el-form-item>
              </el-form>
              <el-form ref="ruleForm" label-width="10rem">
                <el-form-item label="课程详情：" prop="context" class="form-item">
                  <div v-html="ruleForm.context" ref="editor" style="width: 100%; position: relative; z-index: 2" />
                </el-form-item>
                <el-form-item label="课程图片：" prop="thumbnail">
                  <div class="img-el-upload" style="width: 256px">
                    <el-image :src="ruleForm.thumbnail || require('@/assets/develop.png')
            " fit="contain"></el-image>
                  </div>
                </el-form-item>
                <el-form-item label="讲师备注：" prop="lecturer" style="width: 50%">
                  <div style="display: flex">
                    <el-input type="textarea" autosize maxlength="100" :disabled="textareaDisabled"
                      :show-word-limit="true" placeholder="请输入讲师备注" v-model="ruleForm.lecturer" />

                    <div style="
                        display: flex;
                        align-items: flex-end;
                        margin-left: 10px;
                      " v-show="editShow">
                      <el-button class="showbtn bgc-bv" size="mini" @click="textareaEdit">修改</el-button>
                    </div>
                    <div style="
                        display: flex;
                        align-items: flex-end;
                        margin-left: 10px;
                      " v-show="saveShow">
                      <el-button class="showbtn bgc-bv" size="mini" @click="textareaCancle">取消</el-button>
                      <el-button class="showbtn bgc-bv" size="mini" @click="textareaSave">保存</el-button>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <h3 style="padding-bottom: 20px">总学时设置</h3>
            <div class="lessonNum">
              <span>视频总时长：{{ ruleForm.durationStr }}</span>
              <span>总课时数：{{ sectionLessonPeriod(ruleForm.duration) }}</span>
              <span>总课时数(课件累计)：{{
            ruleForm.kpointAccumulateClassHours
          }}</span>
            </div>
            <div class="lessonNum">
              <span>所选课件总学时：{{ lessonNum }}学时</span>
              <span>必修课总学时：{{ lessonNumMust }}学时</span>
              <span>选修课总学时：{{
            (lessonNum - lessonNumMust).toFixed(1) || 0
          }}学时</span>
            </div>
            <p class="lessNumcount" style="margin-bottom: 10px">
              <span>要求完成总学时：</span>
              <!-- <el-input
              v-model="lessnumcount"
              size="small"
              style="width:10rem"
              :disabled="auditState == '30' ? true: false"
            ></el-input>-->
              <el-input-number v-model="lessnumcount" @change="handleChange" size="small" style="width: 10rem"
                controls-position="right" :min="0" :step="0.1" :disabled="auditState == '30' || auditState == '20' || stu ? true : false
            "></el-input-number>
            </p>
            <p class="lessNumcount">
              <span>课时时长：</span>
              <el-select v-model="convertClassHour" placeholder="请选择" style="width: 160px" size="small">
                <el-option v-for="item in convertClassHourList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </p>
            <h3 style="padding-bottom: 20px">课程章节</h3>
            <div style="display: flex">
              <div style="flex: 1">
                <span>视频累计时长:{{ ruleForm.durationStr }}</span>
                <p>
                  <el-button type="text" @click="setAllExpand">一键展开/折叠</el-button>
                </p>
                <section class="chapterl-trees detail">
                  <el-tree ref="tree" :data="data" node-key="id" default-expand-all v-if="data">
                    <div class="custom-tree-node flexac" slot-scope="{ node, data }">
                      <p v-if="data.level == 1">
                        <!-- <span style="margin-right:10px;">一级</span> -->
                        <span style="width: 44px; text-align: left">
                          <span class="level" style="margin-right: 10px">
                            <!-- 一级 -->
                            {{ data.chapterNum }}
                          </span>
                        </span>
                        <span class="ellipsis" style="margin-right: 100px">{{
                          node.label
                        }}</span>
                        <span class="ellipsis" style="width: 5rem; margin-right: 62px">{{ chapterLessonPeriod(data)
                          }}课时</span>
                        <span class="ellipsis">{{
                          chapterDuration(data)
                        }}</span>
                      </p>
                      <template v-else-if="data.level == 2">
                        <!-- <span v-if="styleType == 1" style="margin-right:10px;">二级</span> -->
                        <!-- <span v-else style="margin-right:10px;">一级</span> -->
                        <span style="width: 44px; text-align: left">
                          <span class="level" style="margin-right: 10px">
                            {{ data.chapterNum }}
                          </span>
                        </span>
                        <span v-if="data.list.length > 0" style="
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                          ">
                          <i class="courseIcon" v-if="data.list[0].kpointId"></i>
                          <i class="paperIcon" v-else-if="data.list[0].paperId"></i>
                        </span>
                        <span v-else style="
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                          ">
                          <i class="nullIcon"></i>
                        </span>
                        <span class="ellipsis" style="margin-right: 62px">{{
                          data.label
                        }}</span>
                        <span class="ellipsis" style="width: 5rem; margin-right: 62px">{{
                          sectionLessonPeriod(data.kpointDuration || 0)
                        }}课时</span>
                        <span>{{ data.kpointDurationStr }}</span>
                        <span class="trees-btns" :canDarg="data.list.length">
                          <template v-if="data.list.length">
                            <span class="draggable-item" v-for="element in data.list" :key="element.name">
                              <el-button v-if="element.showTrySeeBtn" class="showbtn bgc-bv" size="mini"
                                @click.stop="() => showVideo(data)">预览</el-button>
                              <el-button @click.stop="() => renameCourseName(data)" size="mini"
                                class="showbtn bgc-bv">重命名</el-button>
                            </span>
                          </template>
                        </span>

                        <span v-if="data.list[0] && data.list[0].kpointDeleted"
                          style="color: #f46173; margin-left: 30px">课件已被删除</span>

                        <span v-if="data.auditState == '40'" style="color: #f46173; margin-left: 30px">审核不通过：</span>
                        <span v-if="data.auditState == '40'" style="color: #f46173; margin-left: 30px">{{ data.auditReason }}</span>
                      </template>
                      <template v-else>
                        <!-- <p class="flexac">
                          <i
                            class="el-icon-tickets"
                            style="margin-right: 3px"
                          ></i>
                          <span class="ellipsis">{{ node.label }}</span>
                          <span class="trees-btns">
                            <el-button
                              size="mini"
                              class="showbtn bgc-bv"
                              style="margin-left: 10px"
                              @click.stop="() => showPaper(data)"
                              >预览</el-button
                            >
                          </span>
                        </p> -->
                        <span class="textellipsis" :title="data.title + ':' + data.catalogName">{{ data.title }}：{{
                          data.catalogName ||
                          (data.title == "授课讲师" ? "无讲师" : "无习题")
                        }}</span>
                        <el-button type="text" @click="showPaper(data)" size="mini" v-show="data.paperId"
                          class="dataqualificationImageId">预览</el-button>
                        <el-button type="text" @click="show(data)" size="mini" v-show="data.qualificationImageId"
                          class="dataqualificationImageId">预览</el-button>
                      </template>
                    </div>
                  </el-tree>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-if="dialogVisible1" :title="videotitle" :visible.sync="dialogVisible1" width="60%"
      :before-close="handleClose">
      <player_2 :videoId="videoId" :courseId="courseId" :kpointId="kpointId" :videoTime="videoTime"
        :kpointSource="kpointSource" v-if="videoType" ref="player2" />
      <player_1 :videoId="videoId" :courseId="courseId" :kpointId="kpointId" :videoTime="videoTime"
        :kpointSource="kpointSource" v-else ref="player2" />
    </el-dialog>
    <PaperShow ref="PaperShow" />
    <el-dialog :title="'授课讲师：' + teacherName" :visible.sync="dialogVisible" width="40%" top="2%">
      <el-image :src="src" class="imgBoxs" oncontextmenu="return false;" ondragstart="return false;" />
    </el-dialog>
    <!-- 重命名课程名称 - 弹框 -->
    <el-dialog title="重命名" :visible.sync="renameCourseNameData.dialogVisible" width="500px" center
      :before-close="renameCourseNameClose">
      <el-form ref="renameCourseNameData" :model="renameCourseNameData" size="small" :rules="renameCourseNameDataRules"
        label-width="100px">
        <el-form-item label="课程名称" prop="catalogName">
          <el-input v-model="renameCourseNameData.catalogName" clearable size="small" placeholder="请输入课程名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="renameCourseNameClose">取 消</el-button>
        <el-button class="bgc-bv" @click="renameCourseNameDetermine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import PaperShow from "@/views/resourse/popup/PaperShow";
import player_1 from "@/components/player";
import player_2 from "@/components/playerTrysee";
import { createSwigger, distorySwigger } from "../../../utils/swigger";
export default {
  name: "detailResource",
  components: {
    PaperShow,
    player_1,
    player_2,
  },
  data() {
    return {
      detailResource: {},
      auditReason: "",
      courseId: "",
      courseOrigin: "",
      projectCourseId: "",
      stu:"",
      styleType: "",
      ruleForm: {
        resource: "",
        thumbnailId: "",
        thumbnail: "",
        context: "", // 富文本
        salesState: "20",
        trainTypeNamePath: "",
        lecturer: "",
      },
      lecture: "",
      // tree
      draggable: true,
      treeLable: "",
      data: [],
      styleStu: false,
      chapterSchema: true,
      auditState: "",
      textareaDisabled: true,
      saveShow: false,
      editShow: true,
      videoId: "", // 視頻id
      videoType: null, // true-可以看；false-试看
      dialogVisible1: false,
      videotitle: "",
      videoTime: "",
      status: 1,
      dialogVisible: false,
      lessonNum: 0, //课程总学时哦
      lessonNumMust: 0, // 必修学时
      lessnumcount: 0, // 要求完成总学时
      convertClassHour: "", //课时时长
      convertClassHourList: [], //课时时长字典
      allTreeState: false, //一键展开/折叠
      // 重命名课程名称 - 弹框数据
      renameCourseNameData: {
        dialogVisible: false, // 弹框状态
        courseOutlineId: "", // 培训班课程大纲id
        catalogName: "", // 重命名名称
      },
      // 重命名课程名称 - 弹框数据校验
      renameCourseNameDataRules: {
        catalogName: [
          {
            required: true,
            message: "请输入课程名称",
            trigger: "blur",
          },
        ]
      },
    };
  },
  computed: {
    // 节课时数换算
    sectionLessonPeriod() {
      return (num) => {
        let res = num / (~~this.convertClassHour * 60);
        return (parseInt(res * 100) / 100).toFixed(2);
      };
    },
    // 章课时数换算
    chapterLessonPeriod() {
      return (data) => {
        if (data.children?.length > 0) {
          let total = 0;
          for (let i = 0; i < data.children.length; i++) {
            const element = data.children[i];
            let res = element.kpointDuration / (~~this.convertClassHour * 60);
            total += +(parseInt(res * 100) / 100).toFixed(2);
          }
          return total.toFixed(2);
        } else {
          return "0";
        }
      };
    },
    // 章视频时长换算
    chapterDuration() {
      return (data) => {
        if (data.children?.length > 0) {
          let total = 0;
          for (let i = 0; i < data.children.length; i++) {
            const element = data.children[i];
            total += element.kpointDuration;
          }
          return this.getTime(total);
        } else {
          return "0";
        }
      };
    },
  },
  created() {
    this.courseId = this.$route.query.courseId;
    this.courseOrigin = this.$route.query.courseOrigin;
    this.projectCourseId = this.$route.query.projectCourseId;
    this.stu = this.$route.query.stu;
    this.getDictionary();
  },
  mounted() {
    this.init();
  },
  methods: {
    // 获取字典
    getDictionary() {
      // 课时时长
      let arr = this.$setDictionary("CONVERT_CLASS_HOUE", "list");
      for (const key in arr) {
        this.convertClassHourList.push({
          label: arr[key],
          value: key,
        });
      }
    },
    // 手动展开/折叠全部节点
    setAllExpand() {
      var nodes = this.$refs.tree.store.nodesMap;
      for (var i in nodes) {
        nodes[i].expanded = this.allTreeState;
      }
      this.allTreeState = !this.allTreeState;
    },
    mapData(data) {
      //样式1下

      data.forEach((item, i) => {
        item["chapterNum"] = Number(i) + 1;
        item.children.forEach((el, k) => {
          el["chapterNum"] = Number(i) + 1 + "-" + (Number(k) + 1);
        });
      });

      this.$nextTick(() => {
        this.data = data;
      });
    },
    init() {
      this.getDetail();
    },
    handleClose() {
      (this.dialogVisible1 = false), (this.status = 3);
      this.$refs.player2.dd();
      distorySwigger();
    },
    getDetail() {
      this.$post("/run/projectCourse/display", {
        courseId: this.courseId,
        projectCourseId: this.projectCourseId,
      }).then((result) => {
        if (result.status === "0") {
          let data = result.data;
          const ret = {
            agreement: data.salesState == "10" ? false : true,
            courseName: data.courseName,
            context: data.context,
            thumbnailId: data.logo,
            salesState: data.salesState,
            thumbnail: data.logoURL,
            lecturer: data.lecturer,
            resource: "",
            trainTypeNamePath: data.trainTypeNamePath,
            postName: data.postName,
            industryNamePath: data.industryNamePath,
            occupationNamePath: data.occupationNamePath,
            trainLevelName: data.trainLevelName,
            auditReason: data.auditReason,
            auditState: data.auditState,
            pushState: data.pushState,
            courseOrigin: data.courseOrigin,
            durationStr: data.durationStr,
            totalClassHours: data.totalClassHours,
            duration: data.duration,
            kpointAccumulateClassHours: data.kpointAccumulateClassHours,
          };
          this.styleType = data.chapterSchema ? 1 : 2;
          this.lecture = ret.lecturer;
          this.ruleForm = ret;
          this.lessonNum = data.projectCourse.lessonNum;
          this.lessonNumMust = data.projectCourse.lessonNumMust;
          this.lessnumcount = data.projectCourse.lessonNumDemand;
          this.convertClassHour = data.projectCourse.convertClassHour || "45";
          let list = [];
          if (data.chapterSchema) {
            data.nodes.forEach((element) => {
              let nodeList = [];
              list.push({
                ind: new Date().getTime(),
                label: element.catalogName,
                id: element.courseKpointId,
                children: nodeList,
                level: 1,
                kpointDuration: element.kpointDuration,
                kpointDurationStr: element.kpointDurationStr,

                kpointSource: element.kpointSource,
                showTrySeeBtn: element.showTrySeeBtn,
                rrsTryUrl: element.rrsTryUrl,
                classHour: element.classHour,
              });
              (element.child || []).forEach((obj) => {
                nodeList.push(this.section2Item(obj));
              });
            });
          } else {
            data.nodes.forEach((element) => {
              list.push(this.section2Item(element));
            });
          }
          this.data = list;
          this.mapData(list);
        }
      });
    },
    section2Item(obj) {
      let children = [];
      children = [
        {
          title: "授课讲师",
          catalogName: obj.teacherPathName,
          courseKpointId: obj.courseKpointId,
          sourceCourseKpointId: obj.courseKpointId,
          level: 3,
          qualificationImageId: obj.qualificationImageId,
        },
        {
          title: "课后习题",
          catalogName: obj.paperName,
          courseKpointId: obj.courseKpointId,
          sourceCourseKpointId: obj.courseKpointId,
          level: 3,
          paperId: obj.paperId,
        },
      ];
      // if (obj.paperId) {
      //   children = [
      //     {
      //       ind: new Date().getTime(),
      //       paperId: obj.paperId,
      //       label: obj.paperName,
      //       level: 3,
      //     },
      //   ];
      // }
      let listArr = [];
      if (obj.kpointVideoId) {
        obj.list = listArr;
        listArr.push({
          kpointVideoId: obj.kpointVideoId,
          kpointName: obj.kpointName,
          kpointDuration: obj.kpointDuration,
          kpointId: obj.kpointId,
          kpointLessonNum: obj.kpointLessonNum,
          kpointLogo: obj.kpointLogo,
          kpointType: obj.kpointType,
          qualificationId: obj.qualificationId,
          kpointDeleted: obj.kpointDeleted ? obj.kpointDeleted : "", // 课件是否删除了
          progress: 100,
          kpointDurationStr: obj.kpointDurationStr,

          kpointSource: obj.kpointSource,
          showTrySeeBtn: obj.showTrySeeBtn,
          rrsTryUrl: obj.rrsTryUrl,
        });
      } else if (obj.paperId) {
        obj.list = listArr;
        listArr.push({
          progress: 100,
          buyId: obj.buyId || "",
          paperId: obj.paperId,
          paperName: obj.paperName,
        });
      }
      //判断kpointId存在，paperId存在，带试卷的课件，kpointId不存在，paperId存在是单元测试
      if (obj.kpointId) {
        return {
          ind: new Date().getTime(),
          label: obj.catalogName,
          id: obj.courseKpointId,
          level: 2,
          list: listArr,
          children: children,
          auditState: obj.auditState,
          auditReason: obj.auditReason,
          kpointId: obj.kpointId,
          kpointDuration: obj.kpointDuration,
          kpointDurationStr: obj.kpointDurationStr,

          kpointSource: obj.kpointSource,
          showTrySeeBtn: obj.showTrySeeBtn,
          rrsTryUrl: obj.rrsTryUrl,
          classHour: obj.classHour,
        };
      } else {
        return {
          ind: new Date().getTime(),
          label: obj.catalogName,
          id: obj.courseKpointId,
          level: 2,
          list: listArr,
          children: [],
          paperId: obj.paperId,
          paperName: obj.paperName,
          auditState: obj.auditState,
          auditReason: obj.auditReason,

          kpointSource: obj.kpointSource,
          showTrySeeBtn: obj.showTrySeeBtn,
          rrsTryUrl: obj.rrsTryUrl,
          classHour: obj.classHour,
        };
      }
    },
    // 预览
    showVideo(data) {
      let _this = this;
      //单元测试打开是题库
      if (data.list[0].kpointVideoId) {
        _this
          .$post("/biz/courseware/auth", {
            videoId: data.list[0].kpointVideoId,
          })
          .then((result) => {
            _this.videoId = data.list[0].kpointVideoId;
            _this.videoType = result.data.trySee;
            _this.videoTime = result.data.duration;
            _this.videotitle = data.label;
            _this.courseId = _this.courseId;
            _this.kpointId = data.kpointId;
            _this.kpointSource = data.list[0].kpointSource;
            _this.dialogVisible1 = true;
          });
      }
      if (!data.list[0].kpointVideoId && data.paperId) {
        this.showPaper(data);
      }
    },
    // 重命名课程名称 - 弹框打开
    renameCourseName(data) {
      this.renameCourseNameData.courseOutlineId = data.id;
      this.renameCourseNameData.catalogName = data.catalogName;
      this.renameCourseNameData.dialogVisible = true;
    },
    // 重命名课程名称 - 弹框打开 - 确定
    renameCourseNameDetermine() {
      this.$refs['renameCourseNameData'].validate((valid) => {
        if (valid) {
          this.$post("/biz/projectCourseOutline/modify/catalogName", {
            courseOutlineId: this.renameCourseNameData.courseOutlineId,
            catalogName: this.renameCourseNameData.catalogName,
          }).then((res) => {
            this.getDetail();
            this.$message({
              message: '更改成功',
              type: 'success'
            });
            this.renameCourseNameClose();
          })
        }
      });
    },
    // 重命名课程名称 - 弹框打开 - 关闭 
    renameCourseNameClose() {
      this.renameCourseNameData = this.$options.data().renameCourseNameData;
    },
    showPaper(data) {
      this.$refs.PaperShow.showPopUp(data.paperId); // 打开弹窗预览试题
    },
    doEdit() {
      if (this.ruleForm.pushState == "20") {
        this.$message({
          message: "课程已推送广州使用不能再编辑",
          type: "error",
        });
      } else {
        let path = "/web/Createcourse";
        this.$router.replace({
          path,
          query: {
            courseId: this.courseId,
            courseOrigin: this.courseOrigin,
          },
        });
      }
    },
    goHerf() {
      this.$router.push({
        name: "myresourseList",
        params: {
          refresh: true,
          isAdd: true,
        },
      });
    },
    /* 讲师备注编辑 */
    textareaEdit() {
      this.textareaDisabled = false;
      this.editShow = false;
      this.saveShow = true;
    },
    textareaCancle() {
      this.textareaDisabled = true;
      this.editShow = true;
      this.saveShow = false;
      this.ruleForm.lecturer = this.lecture;
    },
    textareaSave() {
      this.textareaDisabled = true;
      this.editShow = true;
      this.saveShow = false;
      let parmar = {
        lecturer: this.ruleForm.lecturer,
        projectCourseId: this.projectCourseId,
      };

      this.$post("/run/projectCourse/lecturer/update", parmar)
        .then((ret) => {
          this.$message.success(ret.message);
          this.getDetail();
        })
        .catch((err) => {
          return;
        });
    },
    show(data) {
      this.dialogVisible = true;
      this.teacherName = data.catalogName;
      this.src = data.qualificationImageId;
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
        (m != "00" ? m + "分" : "") +
        (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
};
</script>
<style lang="less">
.formCom {
  .el-form-item {
    margin-bottom: 5px;
  }

  .form-inline {
    width: 45%;

    .el-form-item__content {

      // width: 100%;
      p {
        width: 15rem;
      }
    }
  }

  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }

  .el-cascader {
    width: 100%;
  }

  .el-progress {
    width: 200px;
  }

  .chapterl-trees {
    padding: 15px 15px 15px 0;

    .el-tree-node__content {
      height: 36px;
    }

    .draggable-item {
      margin-left: 5px;

      em {
        margin-right: 5px;
      }
    }

    .trees-btns {
      .showbtn {
        color: #fff;
      }

      margin-left: 15px;

      &>em,
      .handle>em {
        font-size: 14px;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  .chapteru-lists {
    padding: 15px 15px 15px 0;

    .el-divider--horizontal {
      margin: 5px;
    }

    .cl-subhint {
      display: flex;

      span {
        font-size: 14px;
        color: #666;

        &:nth-child(2) {
          margin-left: 15px;
        }
      }

      em {
        margin: 0 5px;
      }
    }

    .cl-btns {
      i {
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  .cl-img-box {
    .el-icon-receiving {
      font-size: 40px;
      color: #999;
      margin-right: 15px;
    }
  }

  .pageForm-btns {
    padding: 15px 0;
    text-align: center;

    .el-button {
      span {
        min-width: 4em;
      }
    }
  }
}

.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}

.detail {
  .ellipsis {
    display: inline-block;
    width: 25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 39px;
  }
}

.doedit {

  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #6676ff;
  }
}

.imgBoxs {
  display: flex;

  >img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    z-index: 1;
  }
}
</style>
<style lang="less" scoped>
.el-textarea {
  .el-textarea__inner {
    min-height: 10px !important;
    height: 100px !important;
    resize: none;
  }
}

.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}

.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
}

.level {
  padding: 3px;
  border-radius: 6px;
  color: #333;
  background-color: #e0f2ff;
  min-width: 20px;
  text-align: center;
}

.lessonNum {
  display: flex;
  padding: 0 1rem 1rem 1rem;

  span {
    padding: 0 1rem;
  }
}

.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;

  span {
    width: 10rem;
  }
}
</style>
